<template>
  <div class="header">
    <!-- 顶部 -->
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- {{$route.meta.breadcrumbList}} -->
        <template v-for="item in $route.meta.breadcrumbList">
          <el-breadcrumb-item :to="{ path: `${item.path}` }" v-if="item.path"><span>{{
            item.name
          }}</span></el-breadcrumb-item>
          <el-breadcrumb-item v-else><span>{{ item.name }}</span></el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
    <div class="userInfo">
      <el-dropdown  @command="cancellation">
        <!-- <i class="el-icon-setting" style="margin-right: 15px"></i> -->
        <!-- <div class="img"></div> -->
      <span class="userName">{{username}}</span>
      <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="personal">个人中心</el-dropdown-item>
          <el-dropdown-item command="back">退出</el-dropdown-item>
          <!-- <el-dropdown-item>删除</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return{
      username: ''
    }
  },
  methods: {
    cancellation(command) {
      console.log(command)
      if(command === 'personal') {
        this.$router.push('/home/personal');
      } else if(command === 'back') {
        sessionStorage.clear();
      this.$router.push('/');
      }
      
    }
  },
  created() {
    this.username = JSON.parse(sessionStorage.getItem('userInfo')).nickname;
  }
};
</script>

<style lang="scss" scoped>
.header {
  // box-shadow: -1px -1px 1px 0px #DAEEFC, 1px 1px 1px 0px #DAEEFC;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  // height: 60px;
  .breadcrumb {
    // line-height: 80px;
    // height: 60px;
    // font-size: 12px;
    display: flex;
    align-items: center;
    span{
      font-size: 14px;
      color: white;
    }
  }
  .userInfo{
    display: flex;
    align-items: center;
    // height: 60px;
    span{
      color: white;
    }
    .userName:hover{
      font-size: 18px;
      font-weight: 600;
    }
    .img{
      // display: inline-block;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      background: #E3E3E3;
    }
  }
}
</style>