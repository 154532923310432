<template>
  <div class="menu">
    <!-- 菜单 -->
    <el-menu class="el-menu-vertical-demo" :default-active="routerPath()" :router="true" background-color="#BAA796"
      width="200px" :unique-opened="true" text-color="#fff" active-text-color="#BAA796">
      <el-submenu v-for="item in menuData" :key="item.id" :index="item.url">
        <span slot="title">{{ item.name }}</span>
        <template v-if="item.children">
          <el-menu-item :index="ele.url" v-for="ele in item.children" :key="ele.id">{{ ele.name }}</el-menu-item>
        </template>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuData: [
        // { id: 1, title: '租户功能', url: '/tenant' },
        {
          id: 2, name: '系统管理', url: '/system', children: [
            { id: 3, name: '组织机构', url: '/home/organization' },
            { id: 4, name: '用户管理', url: '/home/users' },
            { id: 5, name: '权限管理', url: '/home/role' },
            { id: 6, name: '操作日志', url: '/home/logs' }
          ]
        },
        {
          id: 3, name: '量表管理', url: '/gauge/addGauge', children: [
            { id: 4, name: '量表列表', url: '/home/gaugeCategory' },
            // { id: 5, name: '量表组合', url: '/home/gaugeCombination' }
          ]
        },
        {
          id: 6, name: '心理测评', url: '/evaluation', children: [
            { id: 1, name: '测评计划', url: '/home/plan' },
            { id: 2, name: '测评结果', url: '/home/result' },
            { id: 3, name: '答题数据', url: '/home/importData' },
          ]
        },
        {
          id: 8, name: '订单管理', url: '/gaugeCategory5', children: [
            { id: 1, name: '计划订单管理', url: '/home/order' },
            { id: 2, name: '预约订单管理', url: '/home/recordOrder' },
          ]
        },
        {
          id: 10, name: '数据分析', url: '/analysis', children: [
            { id: 1, name: '团体数据分析', url: '/home/groupAnalysis' },
            // { id: 2, name: '个案辅导', url: '/home/coach' }
          ]
        },
        {
          // { id: 1, name: '个人预警', url: '/home/personalWarning' },
          id: 7, name: '危机预警', url: '/gaugeCategory4', children: [
            { id: 1, name: '预警情况', url: '/home/personalWarning' },
            { id: 2, name: '预警统计', url: '/home/warningStatistics' },
            { id: 3, name: '危机干预', url: '/home/crisisIntervention' }]
        },
        
        
        {
          id: 9, name: '心理健康档案', url: '/archives', children: [
            { id: 1, name: '档案管理', url: '/home/fileManagement' },
            { id: 2, name: '个案辅导', url: '/home/coach' }
          ]
        },
        
        {
          id: 11, name: '心理辅导', url: '/consulting', children: [
          { id: 1, name: '预约咨询', url: '/home/appointmentConsultation' },
            { id: 2, name: '咨询设置', url: '/home/consultingTime' },
            { id: 3, name: '咨询师简历', url: '/home/resume' },
            { id: 4, name: '预约须知', url: '/home/notice' },
            // { id: 2, name: '个案辅导', url: '/home/coach' }
          ]
        },
         // 菜单数组  
      ]
    }
  },
  methods: {
    routerPath() {
      // console.log(this.$route.path)
      if ((this.$route.path).indexOf('addGauge') !== -1) {
        return '/home/gaugeCategory'
      } else if ((this.$route.path).indexOf('addPlan') !== -1) {
        return '/home/plan'
      } else if ((this.$route.path) === '/home/viewReport') {
        return '/home/result'
      } else if ((this.$route.path) === '/home/archives') {
        return '/home/crisisIntervention'
      } else if ((this.$route.path) === '/home/answerReport' || (this.$route.path) === '/home/warningReport' || (this.$route.path) === '/home/evaluationReport') {
        return '/home/personalWarning'
      }
      else if ((this.$route.path) === '/home/healthRecords/userInfo' || (this.$route.path) === '/home/healthRecords/record' || (this.$route.path) === '/home/fileDetails2'|| (this.$route.path) === '/home/fileDetails1' || (this.$route.path) === '/home/fileDetails3' || (this.$route.path) === '/home/fileDetails4' || (this.$route.path) === '/home/fileDetails5') {
        return '/home/fileManagement'
      } 
      else if ((this.$route.path) === '/home/consult' || (this.$route.path) === '/home/coachArchives') {
        return '/home/coach'
      }else if ((this.$route.path) === '/home/appointmentReport') {
        return '/home/appointmentConsultation'
      }
      else {
        return this.$route.path
      }
    }
  },
  created() {
    this.menuData = JSON.parse(sessionStorage.getItem('userInfo')).menuList;
    // console.log(JSON.parse(sessionStorage.getItem('userInfo')).menuList);
  }
}
</script>

<style scoped lang="scss">
.menu {
  box-shadow: 2px 0px 2px 0px rgba(3, 31, 48, 0.17);

  .el-menu-item.is-active {
    background: #fff !important;
    // background-color: var(--primary);
  }

  ::v-deep.el-menu-vertical-demo.el-menu {
    border: 0px !important;

  }

  ::v-deep.el-submenu__title i {
    color: white !important;
  }
}
</style>