<template>
  <div class="home">
    <el-container >
      <el-aside class="menu">
        <div class="logo">
          <!-- logo -->
          <img :src="settingInfo.topLogo" alt="">
        </div>
        <MenuVue></MenuVue>
      </el-aside>

      <el-container>
        <el-header style="font-size: 16px">
          <HeaderVue></HeaderVue>
          
        </el-header>
        <el-main>
          <!-- <WebSocketVue></WebSocketVue> -->
          <!-- {{$route.meta.title}} -->
          <div class="container">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {getSetting} from '../../api/settings'
import MenuVue from "../../components/menu/Menu.vue";
import HeaderVue from "../../components/header/Header.vue";
import WebSocketVue from "../webSocket/WebSocket.vue";
export default {
  data() {
    
    return {
      settingInfo: {},
      menuData: [], // 菜单数组
      isCollapse: false, // 控制菜单是否展开
    };
  },
  components: {
    MenuVue,
    HeaderVue,
    WebSocketVue
  },
  methods: {
    getSocket() {
      //获取消息
      var socket = new WebSocket('wss://101.34.136.224:8083/department/list');
      socket.onopen = function () {
        const _this = this
        var userInfo = JSON.parse(localStorage.getItem('userInfo'))//用户的登录信息
        if (userInfo) {
            var login_data = { type: 'init', id: userInfo.id}
//这里是传参，具体的传参与后台进行沟通
            login_data = JSON.stringify(login_data)
            socket.send(login_data)
            console.log("websocket握手成功!")
        }
    }
      let _this = this;
      socket.onmessage = function(e) {
        try {
          let data = JSON.parse(e.data);
          switch (data.message_type) {
            case "init":
              console.log(data.id + "登录成功");
              break;
            case "system_msg":
              _this.$store.commit("getsystemMsgList", data);
              break;
            case "shop_certification_result":
              _this.$store.state.systemMsgList.msg.unshift(data.msg);
              _this.$store.commit(
                "getsystemMsgList",
                _this.$store.state.systemMsgList
              );
              break;
            case "now_msg":
              _this.$store.state.systemMsgList.msg.unshift(data.msg);
              _this.$store.commit(
                "getsystemMsgList",
                _this.$store.state.systemMsgList
              );
              break;
          }
        } catch (err) {
          console.log(err);
        }
      };
    },
    async getSettingInfo() {
      const res = await getSetting();
      // console.log(res);
      if(res.code === 200) {
        this.settingInfo = res.data;
        // sessionStorage.setItem('settingInfo', JSON.stringify(res.data))
      }
    }
  },
  created() {
    this.getSettingInfo();
    // this.getSocket();
  }
};
</script>

<style scoped lang="scss">
.home {
  // position: relative;
  // height: 100vh;
  // overflow-y: scroll;
  // background-color: #BAA796;
  // box-sizing: border-box;
  // min-height: 100%;
  // overflow: hidden;
  
  .el-container{
    height: 100%;
  }
}
.el-header {
  background:  var(--custom-color);
  color: #333;
  line-height: 40px;
  height: 40px !important;
  padding: 0;
  // box-shadow: 1px 0px 1px 0px #A4D6F6;
  box-shadow: 0px 2px 2px 0px rgba(3, 31, 48, 0.15);
}
.el-aside {
  overflow-x: hidden;
  box-sizing: border-box;
  // overflow: hidden;
  color: #333;
  height: 100vh;
  max-width: 200px;
  // box-shadow: 1px 0px 1px 0px #A4D6F6;
  box-shadow: 2px 0px 2px 0px rgba(3, 31, 48, 0.17);
  // position: absolute;
  // z-index: 2;
  // position: sticky;
  padding: 0;
  background: var(--custom-color);
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
  .logo {
    height: 40px;
    width: 100%;
    position: sticky;
    top:0px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background:  var(--custom-color);
    img{
      height: 80%;
    }
  }
}
.el-main{
  background: var(--theme-color);
  // height: 100%;
  // height: 100vh;
  overflow: hidden;
  width: 100%;
  // flex-direction: column;
  // flex-grow: 1;
  // align-content: stretch;
  padding-bottom: 0px !important;
  .container{
    background: white;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    box-shadow: -1px -1px 1px 0px #E5F4FE, 1px 1px 1px 0px #E5F4FE;
  }
}
</style>